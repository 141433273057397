import React, { useEffect, useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Modal from '../Modal';
import LayoutNBItems from './LayoutNBItems';
import {
  GET_INVENTARIO,
  CREAR_INVENTARIO,
  ELIMINAR_INVENTARIO,
  EDITAR_INVENTARIO
} from '../../Queries/inventario';
import { GET_AMBIENTES } from '../../Queries/ambientes';
import {
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  TextField,
  Grid,
  Snackbar,
  Alert,
} from '@mui/material';

const Inventory = ({ idApartamento }) => {
  const { id } = useParams();
  const apartmentId = idApartamento || id;

  const { data: dataAmbientes } = useQuery(GET_AMBIENTES, {
    variables: { id: idApartamento || id }
  });

  const [inventario, setInventarios] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [ambienteSeleccionado, setAmbienteSeleccionado] = useState('');
  const [item, setItem] = useState('');
  const [cantidad, setCantidad] = useState('');
  const [cantidadMin, setCantidadMin] = useState('');
  const [lugar, setLugar] = useState('');
  const [editingId, setEditingId] = useState(null);
  const [texto, setTexto] = useState('');
  const [errors, setErrors] = useState({});

  const openModal = () => {
    if (dataAmbientes?.ambientes.length > 0) {
      setModalOpen(true);
    } else {
      setTexto('Primero debe crear un ambiente!');
      setTimeout(() => setTexto(''), 2000);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setAmbienteSeleccionado('');
    setItem('');
    setCantidad('');
    setCantidadMin('');
    setLugar('');
    setEditingId(null);
    setErrors({});
  };

  const { data: dataInventario, refetch } = useQuery(GET_INVENTARIO, {
    variables: { apartamentoId: apartmentId },
  });

  useEffect(() => {
    refetch();
    if (dataInventario && dataInventario.inventario) {
      setInventarios(dataInventario.inventario);
    }
  }, [dataInventario]);

  const [crearInventario] = useMutation(CREAR_INVENTARIO, {
    onCompleted: () => {
      closeModal();
      refetch();
    },
  });

  const [editarInventario] = useMutation(EDITAR_INVENTARIO, {
    onCompleted: () => {
      closeModal();
      refetch();
    },
  });

  const [deleteItem] = useMutation(ELIMINAR_INVENTARIO, {
    onCompleted: () => {
      refetch();
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    let formErrors = {};

    if (!ambienteSeleccionado) formErrors.ambienteSeleccionado = 'Seleccione un ambiente.';
    if (!item.trim()) formErrors.item = 'El nombre del objeto es obligatorio.';
    if (!cantidad) formErrors.cantidad = 'La cantidad es obligatoria.';
    if (!cantidadMin) formErrors.cantidadMin = 'La cantidad mínima es obligatoria.';
    if (!lugar.trim()) formErrors.lugar = 'El lugar es obligatorio.';

    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      if (editingId) {
        editarInventario({
          variables: {
            inventarioId: editingId,
            inputInventario: { nombre: item, cantidad: parseInt(cantidad, 10), cantidad_min: parseInt(cantidadMin, 10), lugar, ambienteId: ambienteSeleccionado }
          }
        });
      } else {
        crearInventario({
          variables: {
            ambienteId: ambienteSeleccionado,
            apartamentoId: apartmentId,
            inputInventario: { nombre: item, cantidad: parseInt(cantidad, 10), cantidad_min: parseInt(cantidadMin, 10), lugar }
          }
        });
      }
    }
  };

  const handleEdit = (item) => {
    setEditingId(item._id);
    setAmbienteSeleccionado(item.ambienteId._id);
    setItem(item.nombre);
    setCantidad(item.cantidad);
    setCantidadMin(item.cantidad_min);
    setLugar(item.lugar);
    setModalOpen(true);
  };

  const handleDelete = (id) => {
    deleteItem({ variables: { inventarioId: id } });
  };

  return (
    <LayoutNBItems>
      <Typography variant="h4">Inventario</Typography>
      <Typography variant="body1">Administra el inventario de la propiedad.</Typography>
      <Button variant="contained" color="primary" onClick={openModal}>
        Nuevo
      </Button>
      {texto && (
        <Snackbar open={true} autoHideDuration={6000} onClose={() => setTexto('')}>
          <Alert onClose={() => setTexto('')} severity="error">
            {texto}
          </Alert>
        </Snackbar>
      )}
      {inventario.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Cantidad</TableCell>
                <TableCell>Cantidad Mínima</TableCell>
                <TableCell>Ambiente</TableCell>
                <TableCell>Lugar</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inventario.map((item) => (
                <TableRow key={item._id}>
                  <TableCell>{item.nombre}</TableCell>
                  <TableCell>{item.cantidad}</TableCell>
                  <TableCell>{item.cantidad_min}</TableCell>
                  <TableCell>{item.ambienteId.nombre}</TableCell>
                  <TableCell>{item.lugar}</TableCell>
                  <TableCell>
                    <Button color="secondary" onClick={() => handleEdit(item)}>
                      Editar
                    </Button>
                    <Button color="error" onClick={() => handleDelete(item._id)}>
                      Eliminar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No hay ningún item creado aún</Typography>
      )}

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <form onSubmit={handleSubmit}>
          <Typography variant="h6">{editingId ? 'Editar Artículo' : 'Crear Artículo'}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Select
                fullWidth
                value={ambienteSeleccionado}
                onChange={(e) => setAmbienteSeleccionado(e.target.value)}
                displayEmpty
              >
                <MenuItem value=''>Seleccione un ambiente</MenuItem>
                {dataAmbientes && dataAmbientes.ambientes.map(ambiente => (
                  <MenuItem key={ambiente._id} value={ambiente._id}>
                    {`${ambiente.nombre} ${ambiente.detalles ? `(${ambiente.detalles})` : ''}`}
                  </MenuItem>
                ))}
              </Select>
              {errors.ambienteSeleccionado && <Typography color="error">{errors.ambienteSeleccionado}</Typography>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Item"
                value={item}
                onChange={(e) => setItem(e.target.value)}
                placeholder="Nombre objeto"
                error={!!errors.item}
                helperText={errors.item}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                label="Cantidad"
                value={cantidad}
                onChange={(e) => setCantidad(e.target.value)}
                placeholder="Cantidad"
                error={!!errors.cantidad}
                helperText={errors.cantidad}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                label="Cantidad Mínima"
                value={cantidadMin}
                onChange={(e) => setCantidadMin(e.target.value)}
                placeholder="Cantidad mínima para ser alquilado"
                error={!!errors.cantidadMin}
                helperText={errors.cantidadMin}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Lugar"
                value={lugar}
                onChange={(e) => setLugar(e.target.value)}
                placeholder="Lugar donde se encuentra el objeto"
                error={!!errors.lugar}
                helperText={errors.lugar}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit">
                {editingId ? 'Actualizar' : 'Crear'}
              </Button>
              <Button variant="outlined" onClick={closeModal}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </LayoutNBItems>
  );
};

export default Inventory;
