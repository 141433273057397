import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import Modal from '../Modal';
import { Link, useNavigate } from 'react-router-dom';
import { GET_APARTAMENTOS_QUERY, DELETE_APARTAMENTO_QUERY } from '../../Queries/apartamentos';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress, Typography } from '@mui/material';
import { Home as HomeIcon, QrCode as QrCodeIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

function ListadoApartamentos() {
  const [isQrModalOpen, setQrModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedQrCodeUrl, setSelectedQrCodeUrl] = useState('');
  const [selectedAptoDelete, setSelectedAptoDelete] = useState('');
  const navigate = useNavigate();

  const { loading, error, data, refetch } = useQuery(GET_APARTAMENTOS_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  const [deleteApartamento, { loading: deleteLoading, error: deleteError }] = useMutation(DELETE_APARTAMENTO_QUERY, {
    onCompleted: (data) => {
      if (data.eliminarApartamento.success) {
        closeDeleteModal();
        refetch();
      } else {
        console.error(data.eliminarApartamento.message);
      }
    },
  });

  const handleDeleteApartamento = () => {
    deleteApartamento({ variables: { id: selectedAptoDelete } });
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error.message}</Typography>;

  const openQrModal = (qrCodeUrl) => {
    setSelectedQrCodeUrl(qrCodeUrl);
    setQrModalOpen(true);
  };

  const openDeleteModal = (idApto) => {
    setSelectedAptoDelete(idApto);
    setDeleteModalOpen(true);
  };

  const closeQrModal = () => {
    setQrModalOpen(false);
    setSelectedQrCodeUrl('');
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setSelectedAptoDelete('');
  };

  const redirect = (path) => {
    navigate(path);
  };

  const downloadQRCode = (qrCodeUrl) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = qrCodeUrl;
    downloadLink.download = 'codigo_qr.png';
    downloadLink.click();
  };

  return (
    <div className="layoutItem listadoAptos">
      <Button
        variant="contained"
        startIcon={<HomeIcon />}
        component={Link}
        to="/crearApartamento"
        sx={{ mb: 2 }}
      >
        Nueva Unidad
      </Button>

      {data.apartamentos.length === 0 ? (
        <Typography>No hay apartamentos creados todavía.</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Dirección</TableCell>
                <TableCell>Apartamento</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.apartamentos.map((apartamento, index) => (
                <TableRow key={index}>
                  <TableCell>{apartamento.calle} {apartamento.numero}</TableCell>
                  <TableCell>{apartamento.num_apto}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => openQrModal(apartamento.qrCodeUrl)}>
                      <QrCodeIcon />
                    </IconButton>
                    <IconButton onClick={() => redirect(`/editarApartamento/${apartamento.id}`)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => openDeleteModal(apartamento.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={isQrModalOpen} onClose={closeQrModal}>
        <DialogTitle>Código QR</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <img src={selectedQrCodeUrl} alt="Código QR" style={{ width: '100%' }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => downloadQRCode(selectedQrCodeUrl)}>Descargar</Button>
          <Button onClick={closeQrModal}>Cerrar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isDeleteModalOpen} onClose={closeDeleteModal}>
        <DialogTitle>Eliminar Apartamento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Seguro que quieres eliminar la unidad? Se perderán los datos asociados, incluyendo las inspecciones realizadas.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteApartamento} disabled={deleteLoading}>
            {deleteLoading ? <CircularProgress size={24} /> : 'Sí, eliminar.'}
          </Button>
          <Button onClick={closeDeleteModal} color='error'>No, cancelar.</Button>
        </DialogActions>
        {deleteError && <Typography color="error">{deleteError.message}</Typography>}
      </Dialog>
    </div>
  );
}

export default ListadoApartamentos;
