import { gql } from "@apollo/client";

export const GET_TAREAS = gql`
  query GetTareas($id: ID!) {
    tareas(apartamentoId: $id) {
      _id
      nombre
      descripcion
      oculta
      apartamentoId {
        calle
      }
      ambienteId {
        nombre
        detalles
      }
      creadorId {
        nombre
      }
      completada
      fecha
    }
  }
`;

export const CREAR_TAREA = gql`
  mutation CrearTarea($inputTarea: InputTarea!) {
    crearTarea(inputTarea: $inputTarea) {
      _id
    }
  }
`;

export const EDITAR_TAREA = gql`
  mutation EditarTarea($tareaId: ID!, $inputTarea: InputTarea!) {
    editarTarea(tareaId: $tareaId, inputTarea: $inputTarea) {
      _id
    }
  }
`;

export const ELIMINAR_TAREA = gql`
  mutation EliminarTarea($tareaId: ID!) {
    eliminarTarea(tareaId: $tareaId) {
      success
      message
    }
  }
`;