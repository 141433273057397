import Login from './Components/Login/index.js';
import { Routes, Route, Navigate } from 'react-router-dom';
import Protected from './Components/Protected/index.js';
import ProtectedRoute from './Components/Protected/protectedRoute.js';
import NotFound from './Components/Protected/notFound.js';
import CrearApartamento from './Components/Protected/crearApartamento.js';
import EditarApartamento from './Components/Protected/editarApartamento.js';
import Layout from './Components/Protected/Layout.js';
import Hisorial from './Components/Protected/Historial.js';
// import SignUp from './Components/SignUp/index.js';
import DetalleInspeccion from './Components/Protected/DetalleInspeccion.js';
import Empresa from './Components/Protected/Empresa.js';
import Persona from './Components/Protected/Persona.js';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      {/* <Route path="/signup" element={<SignUp />} /> */}
      <Route path="/unidades" element={
        <ProtectedRoute>
          <Layout>
            <Protected />
          </Layout>
        </ProtectedRoute>
      } />
      <Route path="/editarApartamento/:id" element={
        <ProtectedRoute>
          <Layout>
            <EditarApartamento />
          </Layout>
        </ProtectedRoute>
      } />
      <Route path="/crearApartamento" element={
        <ProtectedRoute>
          <Layout>
            <CrearApartamento />
          </Layout>
        </ProtectedRoute>
      } />
      <Route path="/historial" element={
        <ProtectedRoute>
          <Layout>
            <Hisorial />
          </Layout>
        </ProtectedRoute>
      } />
      <Route path="/historial/:id" element={
        <ProtectedRoute>
          <Layout>
            <DetalleInspeccion />
          </Layout>
        </ProtectedRoute>
      } />
      <Route path="/empresas" element={
        <ProtectedRoute>
          <Layout>
            <Empresa />
          </Layout>
        </ProtectedRoute>
      } />
      <Route path="/personas" element={
        <ProtectedRoute>
          <Layout>
            <Persona />
          </Layout>
        </ProtectedRoute>
      } />
      <Route path="/apartamento/:id" element={<Login/>} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}

export default App;
