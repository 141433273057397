import React, { useEffect, useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import LayoutNBItems from './LayoutNBItems';
import Modal from '../Modal';
import { GET_AMBIENTES } from '../../Queries/ambientes';
import { useParams } from 'react-router-dom';
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';
import { GET_TAREAS, CREAR_TAREA, EDITAR_TAREA, ELIMINAR_TAREA } from '../../Queries/tareas';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Snackbar, Grid, Select, MenuItem, Checkbox, FormControlLabel } from '@mui/material';

const TaskList = ({ idApartamento }) => {
  const { id } = useParams();
  const apartmentId = idApartamento || id;

  const { data: dataAmbientes, loading: ambientesLoading, error: ambientesError, refetch: refetchAmbientes } = useQuery(GET_AMBIENTES, {
    variables: { id: apartmentId },
  });

  const [ambienteSeleccionado, setAmbienteSeleccionado] = useState('');
  const [tareas, setTareas] = useState([]);
  const [tarea, setTarea] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [oculta, setOculta] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [texto, setTexto] = useState('');
  const [errorTarea, setErrorTarea] = useState('');
  const [errorDescripcion, setErrorDescripcion] = useState('');
  const [errorAmbiente, setErrorAmbiente] = useState('');

  const { data, loading: queryLoading, error: queryError, refetch } = useQuery(GET_TAREAS, {
    variables: { id: apartmentId },
  });

  const [crearTarea, { loading: mutationLoadingCrear, error: mutationErrorCrear }] = useMutation(CREAR_TAREA, {
    onCompleted: () => {
      refetch();
      closeModal();
    },
  });

  const [editarTarea, { loading: mutationLoadingEditar, error: mutationErrorEditar }] = useMutation(EDITAR_TAREA, {
    onCompleted: () => {
      refetch();
      closeModal();
    },
  });

  const [eliminarTarea, { loading: mutationLoadingEliminar, error: mutationErrorEliminar }] = useMutation(ELIMINAR_TAREA, {
    onCompleted: () => {
      refetch();
    },
  });

  useEffect(() => {
    refetch();
    refetchAmbientes();
    if (data && data.tareas && dataAmbientes) {
      setTareas(data.tareas);
    }
  }, [data]);

  const openModal = () => {
    dataAmbientes.ambientes.length > 0
      ? setModalOpen(true)
      : setTexto('Primero debe crear un ambiente!');
    setTimeout(() => {
      setTexto('');
    }, 2000);
  };

  const closeModal = () => {
    setTarea('');
    setDescripcion('');
    setOculta(false);
    setAmbienteSeleccionado('');
    setEditingId(null);
    setErrorTarea('');
    setErrorDescripcion('');
    setErrorAmbiente('');
    setModalOpen(false);
    refetch();
  };

  const handleEdit = (tarea) => {
    setEditingId(tarea._id);
    setTarea(tarea.nombre);
    setDescripcion(tarea.descripcion);
    setOculta(tarea.oculta);
    setAmbienteSeleccionado(tarea.ambienteId._id);
    openModal();
  };

  const handleDelete = (id) => {
    setEditingId(id);
    eliminarTarea({ variables: { tareaId: id } });
  };

  const validateForm = () => {
    let valid = true;
    if (!tarea) {
      setErrorTarea('El nombre de la tarea no puede estar vacío.');
      valid = false;
    } else {
      setErrorTarea('');
    }

    if (!ambienteSeleccionado) {
      setErrorAmbiente('Seleccione un ambiente.');
      valid = false;
    } else {
      setErrorAmbiente('');
    }

    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    if (editingId) {
      editarTarea({
        variables: { tareaId: editingId, inputTarea: { ambienteId: ambienteSeleccionado, apartamentoId: apartmentId, nombre: tarea, descripcion, oculta } }, // Incluir "oculta"
      });
    } else {
      crearTarea({
        variables: { inputTarea: { ambienteId: ambienteSeleccionado, apartamentoId: apartmentId, nombre: tarea, descripcion, oculta } }, // Incluir "oculta"
      });
    }

    setTarea('');
    setDescripcion('');
    setOculta(false); // Restablecer "oculta"
    setAmbienteSeleccionado('');
    setEditingId(null);
  };

  return (
    <LayoutNBItems>
      <Typography variant="h4">Listado de tareas</Typography>
      <Typography variant="body1">Administra el listado de tareas de la propiedad.</Typography>

      {ambientesLoading || queryLoading || mutationLoadingCrear || mutationLoadingEditar || mutationLoadingEliminar ? (
        <Typography>Cargando...</Typography>
      ) : (
        <>
          <Button variant="contained" color="primary" onClick={openModal}>
            Nuevo
          </Button>
          <Typography style={{ color: 'red' }}>{texto}</Typography>

          {tareas.length > 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Tarea</TableCell>
                    <TableCell>Ambiente</TableCell>
                    <TableCell>Oculta</TableCell>
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tareas
                    .filter((task) => task.creadorId !== null)
                    .map((task) => (
                      <TableRow key={task._id}>
                        <TableCell>{task.nombre}</TableCell>
                        <TableCell>
                          {task.ambienteId.nombre}
                          {task.ambienteId.detalles ? ` (${task.ambienteId.detalles})` : ''}
                        </TableCell>
                        <TableCell>{task.oculta ? 'Si' : 'No'}</TableCell>
                        <TableCell>
                          <Button onClick={() => handleEdit(task)}>Editar</Button>
                          <Button onClick={() => handleDelete(task._id)} color="secondary">Eliminar</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No hay tareas creadas aún</Typography>
          )}

          {mutationErrorCrear && <Typography className="error">Error al crear tarea: {mutationErrorCrear.message}</Typography>}
          {mutationErrorEditar && <Typography className="error">Error al editar tarea: {mutationErrorEditar.message}</Typography>}
          {mutationErrorEliminar && <Typography className="error">Error al eliminar tarea: {mutationErrorEliminar.message}</Typography>}
        </>
      )}

      {queryError && <Typography className="error">Error al cargar tareas: {queryError.message}</Typography>}
      {ambientesError && <Typography className="error">Error al cargar ambientes: {ambientesError.message}</Typography>}

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <form onSubmit={handleSubmit}>
          <Typography variant="h6">{editingId ? 'Editar Tarea' : 'Crear Tarea'}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Tarea"
                value={tarea}
                onChange={(e) => setTarea(e.target.value)}
                placeholder="Nombre de la tarea"
                error={!!errorTarea}
                helperText={errorTarea}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descripción"
                multiline
                rows={4}
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
                placeholder="Descripción de la tarea"
                error={!!errorDescripcion}
                helperText={errorDescripcion}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                value={ambienteSeleccionado || ''} // Asegúrate de que siempre tenga un valor
                onChange={(e) => setAmbienteSeleccionado(e.target.value)}
                displayEmpty
              >
                <MenuItem value=''>Seleccione un ambiente</MenuItem>
                {dataAmbientes && dataAmbientes.ambientes.map(ambiente => (
                  <MenuItem key={ambiente._id} value={ambiente._id}>
                    {`${ambiente.nombre} ${ambiente.detalles ? `(${ambiente.detalles})` : ''}`}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              {/* Checkbox para ocultar/desocultar la tarea */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!oculta}
                    onChange={(e) => setOculta(e.target.checked)}
                    color="primary"
                  />
                }
                label="Ocultar tarea"
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit">
                {editingId ? 'Guardar Cambios' : 'Crear Tarea'}
              </Button>
              <Button variant="outlined" onClick={closeModal} style={{ marginLeft: '10px' }}>Cancelar</Button>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </LayoutNBItems>
  );
};

export default TaskList;
